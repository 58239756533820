<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Device</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="f-w-600" for="id_brand">Brand </label>
                  <select
                    v-model="form.id_brand"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_brand = ''"
                    :class="{
                      'is-valid': form.id_brand != '',
                      'is-invalid':
                        formValidate.id_brand || form.id_brand == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="brand in listBrand"
                      :key="brand.id_brand"
                      :value="brand.id_brand"
                    >
                      {{ brand.nama_brand }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="f-w-600" for="id_type">Type </label>
                  <select
                    v-model="form.id_type"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_type = ''"
                    :class="{
                      'is-valid': form.id_type != '',
                      'is-invalid': formValidate.id_type || form.id_type == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="tipe in listType"
                      :key="tipe.id_type"
                      :value="tipe.id_type"
                    >
                      {{ tipe.nama_type }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="f-w-600" for="id_area">Area </label>
                  <select
                    v-model="form.id_area"
                    type="text"
                    class="form-control"
                    required
                    @change="
                      formValidate.id_area = '';
                      getLokasi();
                    "
                    :class="{
                      'is-valid': form.id_area != '',
                      'is-invalid': formValidate.id_area || form.id_area == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="area in listArea"
                      :key="area.id_area"
                      :value="area.id_area"
                    >
                      {{ area.nama_area }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="f-w-600" for="id_lokasi">Lokasi </label>
                  <select
                    v-model="form.id_lokasi"
                    type="text"
                    class="form-control"
                    required
                    @change="
                      formValidate.id_lokasi = '';
                      getSubLokasi();
                    "
                    :class="{
                      'is-valid': form.id_lokasi != '',
                      'is-invalid':
                        formValidate.id_lokasi || form.id_lokasi == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="lokasi in listLokasi"
                      :key="lokasi.id_lokasi"
                      :value="lokasi.id_lokasi"
                    >
                      {{ lokasi.nama_lokasi }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="f-w-600" for="id_sublokasi">Sub Lokasi </label>
                  <select
                    v-model="form.id_sublokasi"
                    type="text"
                    class="form-control"
                    required
                    @change="
                      formValidate.id_sublokasi = '';
                      getDevice();
                    "
                    :class="{
                      'is-valid': form.id_sublokasi != '',
                      'is-invalid':
                        formValidate.id_sublokasi || form.id_sublokasi == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="lokasi in listSubLokasi"
                      :key="lokasi.id_sublokasi"
                      :value="lokasi.id_sublokasi"
                    >
                      {{ lokasi.nama_sublokasi }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="f-w-600" for="id_equipment">Equipment </label>
                  <select
                    v-model="form.id_equipment"
                    type="text"
                    class="form-control"
                    required
                    @change="
                      formValidate.id_equipment = '';
                      getLokasi();
                    "
                    :class="{
                      'is-valid': form.id_equipment != '',
                      'is-invalid': formValidate.id_equipment || form.id_equipment == '',
                    }"
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="equipment in listEquipment"
                      :key="equipment.id_equipment"
                      :value="equipment.id_equipment"
                    >
                      {{ equipment.nama_equipment }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="f-w-600" for="nama_device">Nama Device </label>
                  <input
                    v-model="form.nama_device"
                    type="text"
                    class="form-control"
                    placeholder="Input Nama Device"
                    required
                    @input="formValidate.nama_device = ''"
                    :class="{
                      'is-valid': form.nama_device != '',
                      'is-invalid':
                        formValidate.nama_device || form.nama_device == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="f-w-600" for="sn_device">SN Device </label>
                  <input
                    v-model="form.sn_device"
                    type="text"
                    class="form-control"
                    placeholder="Input SN Device"
                    @input="formValidate.sn_device = ''"
                    
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="f-w-600" for="ip_device"
                    >IP Address Device</label
                  >
                  <input
                    v-model="form.ip_device"
                    type="text"
                    class="form-control"
                    placeholder="Input IP Address Device"
                    required
                    @input="formValidate.ip_device = ''"
                    :class="{
                      'is-valid': form.ip_device != '',
                      'is-invalid':
                        formValidate.ip_device || form.ip_device == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="f-w-600" for="username_device"
                    >Username Device</label
                  >
                  <input
                    v-model="form.username_device"
                    type="text"
                    class="form-control"
                    placeholder="Input Username Device"
                    required
                    @input="formValidate.username_device = ''"
                    :class="{
                      'is-valid': form.username_device != '',
                      'is-invalid':
                        formValidate.username_device ||
                        form.username_device == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="f-w-600" for="password_device"
                    >Password Device</label
                  >
                  <input
                    v-model="form.password_device"
                    type="text"
                    class="form-control"
                    placeholder="Input Password Device"
                    required
                    @input="formValidate.password_device = ''"
                    :class="{
                      'is-valid': form.password_device != '',
                      'is-invalid':
                        formValidate.password_device ||
                        form.password_device == '',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <div class="row">
              <div class="col-md-6 mb-2">
                <a
                  href="javascript:window.history.go(-1);"
                  class="btn btn-default"
                  >Kembali</a
                >
                <button @click="showModalBrand" class="btn btn-warning ml-1">
                  Tambah Brand Baru
                </button>
                <button @click="showModalType" class="btn btn-warning ml-1">
                  Tambah Type Baru
                </button>
              </div>
              <div class="col-md-6">
                <button
                  class="btn btn-success float-md-right"
                  @click="postData"
                  variant="primary"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal brand -->
    <b-modal v-model="modalVisibleBrand" @hide="beforeModalBrandClose">
      <template #modal-header>
        <h5 class="modal-title">Tambah Brand</h5>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="f-w-600" for="nama_cat_maintenance"
                >Nama Brand
              </label>
              <input
                v-model="brand.nama_brand"
                type="tel"
                class="form-control"
                placeholder="Input Nama Brand"
                required
                @input="formValidate.nama_brand = ''"
                :class="{
                  'is-valid': brand.nama_brand != '',
                  'is-invalid':
                    formValidate.nama_brand || brand.nama_brand == '',
                }"
              />
            </div>
          </div>
          <div class="col-md-12">
            <b-alert v-if="statusBrand" variant="success" show fade>
              <strong>Success!</strong>
              <br />
              <span>{{ brandAction }} Brand Sukses</span>
            </b-alert>
          </div>
          <div class="col-md-12">
            <div style="max-height: 50vh; overflow-y: scroll">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr class="text-center">
                    <th>Nama Brand</th>
                    <th style="width: 100px"><i class="fa fa-cogs"></i></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="brand in listBrand" :key="brand.id_brand">
                    <td>{{ brand.nama_brand }}</td>
                    <td class="text-center">
                      <button
                        class="btn btn-icon btn-warning m-r-5"
                        v-on:click="editBrand(brand.id_brand, brand.nama_brand)"
                      >
                        <i class="fa fa-pencil-alt"></i>
                      </button>
                      <button
                        class="btn btn-icon btn-danger"
                        v-on:click="confirmDelete(brand.id_brand, 'brand')"
                      >
                        <i class="fa fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button @click="closeModalBrand" class="btn btn-info" variant="primary">
          Tutup
        </button>
        <button
          class="btn btn-success"
          @click="postBrand"
          variant="primary"
          :disabled="loadingBrand"
        >
          <i v-if="loadingBrand" class="fas fa-circle-notch fa-spin"></i>
          Submit
        </button>
      </template>
    </b-modal>
    <!-- modal type -->
    <b-modal v-model="modalVisibleType" @hide="beforeModalTypeClose">
      <template #modal-header>
        <h5 class="modal-title">Tambah Type</h5>
      </template>
      <div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label class="f-w-600" for="nama_cat_maintenance"
                >Nama Type
              </label>
              <input
                v-model="type.nama_type"
                type="tel"
                class="form-control"
                placeholder="Input Nama Type"
                required
                @input="formValidate.nama_type = ''"
                :class="{
                  'is-valid': type.nama_type != '',
                  'is-invalid': formValidate.nama_type || type.nama_type == '',
                }"
              />
            </div>
          </div>
          <div class="col-md-12">
            <b-alert v-if="statusType" variant="success" show fade>
              <strong>Success!</strong>
              <br />
              <span>{{ typeAction }} Type Sukses</span>
            </b-alert>
          </div>
          <div class="col-md-12">
            <div style="max-height: 50vh; overflow-y: scroll">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr class="text-center">
                    <th>Nama Type</th>
                    <th style="width: 100px"><i class="fa fa-cogs"></i></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="type in listType" :key="type.id_type">
                    <td>{{ type.nama_type }}</td>
                    <td class="text-center">
                      <button
                        class="btn btn-icon btn-warning m-r-5"
                        v-on:click="editType(type.id_type, type.nama_type)"
                      >
                        <i class="fa fa-pencil-alt"></i>
                      </button>
                      <button
                        class="btn btn-icon btn-danger"
                        v-on:click="confirmDelete(type.id_type, 'type')"
                      >
                        <i class="fa fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <button @click="closeModalType" class="btn btn-info" variant="primary">
          Tutup
        </button>
        <button
          class="btn btn-success"
          @click="postType"
          variant="primary"
          :disabled="loadingType"
        >
          <i v-if="loadingType" class="fas fa-circle-notch fa-spin"></i>
          Submit
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../../plugins/userData";

export default {
  mixins: [userData],
  data() {
    return {
      form: {
        id_equipment: "",
        id_area: "",
        id_lokasi: "",
        id_sublokasi: "",
        id_brand: "",
        id_type: "",
        nama_device: "",
        sn_device: "",
        ip_device: "",
        username_device: "",
        password_device: "",
      },
      brand: {
        id_brand: "",
        nama_brand: "",
      },
      type: {
        id_type: "",
        nama_type: "",
      },
      listEquipment: [],
      listArea: [],
      listLokasi: [],
      listSubLokasi: [],
      listBrand: [],
      listType: [],
      formValidate: [],
      loading: false,
      loadingBrand: false,
      modalVisibleBrand: false,
      conditionMetBrand: false,
      statusBrand: false,
      brandAction: "Create",
      loadingType: false,
      modalVisibleType: false,
      conditionMetType: false,
      statusType: false,
      typeAction: "Create",
    };
  },
  mounted() {
    this.getBrand();
    this.getType();
    this.getArea();
    this.getEquipment();
  },
  methods: {
    getBrand() {
      this.listBrand = [];
      axios
        .get(this.$url+"/v2/brand/")
        .then((response) => {
          this.listBrand = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getType() {
      axios
        .get(this.$url+"/v2/type/")
        .then((response) => {
          this.listType = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getEquipment() {
      axios
        .get(this.$url+"/v2/equipment/option/")
        .then((response) => {
          this.listEquipment = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getArea() {
      axios
        .get(this.$url+"/v2/area/option/")
        .then((response) => {
          this.listArea = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getLokasi() {
      if (this.form.id_area) {
        axios
          .get(this.$url+"/v2/lokasi/option/" + this.form.id_area)
          .then((response) => {
            this.listLokasi = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    getSubLokasi() {
      if (this.form.id_lokasi) {
        axios
          .get(this.$url+"/v2/sublokasi/option/" + this.form.id_lokasi)
          .then((response) => {
            this.listSubLokasi = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.$url+"/v2/device/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    postBrand() {
      this.loadingBrand = true;
      const formData = new FormData();
      for (let key in this.brand) {
        formData.append(key, this.brand[key]);
      }
      axios
        .post(`${this.$url}/v2/brand/${this.brandAction}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loadingBrand = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loadingBrand = false;
            this.statusBrand = true;
            setTimeout(() => {
              this.statusBrand = false;
              this.brandAction = "Create";
              this.brand.id_brand = "";
              this.brand.nama_brand = "";
              this.getBrand();
            }, 1000);
          }
        })
        .catch((error) => {
          this.loadingBrand = false;
          console.log(error);
          this.error = error.message;
        });
    },
    postType() {
      this.loadingType = true;
      const formData = new FormData();
      for (let key in this.type) {
        formData.append(key, this.type[key]);
      }
      axios
        .post(`${this.$url}/v2/type/${this.typeAction}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loadingType = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loadingType = false;
            this.statusType = true;
            setTimeout(() => {
              this.statusType = false;
              this.typeAction = "Create";
              this.type.id_type = "";
              this.type.nama_type = "";
              this.getType();
            }, 1000);
          }
        })
        .catch((error) => {
          this.loadingType = false;
          console.log(error);
          this.error = error.message;
        });
    },
    showModalBrand() {
      this.getBrand();
      this.modalVisibleBrand = true;
      this.conditionMetBrand = false;
    },
    closeModalBrand() {
      this.modalVisibleBrand = false;
      this.conditionMetBrand = true;
      this.brandAction = "Create";
      this.brand.id_brand = "";
      this.brand.nama_brand = "";
    },
    showModalType() {
      this.modalVisibleType = true;
      this.conditionMetType = false;
    },
    closeModalType() {
      this.modalVisibleType = false;
      this.conditionMetType = true;
      this.typeAction = "Create";
      this.type.id_type = "";
      this.type.nama_type = "";
    },
    beforeModalBrandClose(evt) {
      if (!this.conditionMetBrand) {
        evt.preventDefault();
      }
    },
    beforeModalTypeClose(evt) {
      if (!this.conditionMetType) {
        evt.preventDefault();
      }
    },
    confirmDelete(id, modul) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id, modul);
        }
      });
    },
    deleteData(id, modul) {
      try {
        axios
          .get(`${this.$url}/v2/${modul}/delete/${id}`)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                if (modul == "brand") {
                  this.getBrand();
                } else {
                  this.getType();
                }
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Gagal",
              text: error.response.data.message,
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.fetchData();
            });
            console.log(error.response.data.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    editBrand(id_brand, nama_brand) {
      this.brandAction = "Update";
      this.brand.id_brand = id_brand;
      this.brand.nama_brand = nama_brand;
    },
    editType(id_type, nama_type) {
      this.typeAction = "Update";
      this.type.id_type = id_type;
      this.type.nama_type = nama_type;
    },
  },
};
</script>